body.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.app {
  display: flex;
}

.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 20px;
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 200px;
}
@media (max-width: 992px) {
  .main-content {
    width: 100%;
    margin-left: 0;
    padding-top: 2.5em;
  }
  .header {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    padding-left: 2em;
  }
  .header h1 {
    font-size: 20px;
  }
}

.header {
  color: #000;
  padding: 10px;
  text-align: center;
}

.container {
  padding: 20px;
}

.menu-items {
  padding: 0;
}
.menu-top-item {
  padding: 5px !important;
}
.menu-top-item:hover {
  text-decoration: underline;
}
.menu-items .accordion-collapse {
  padding-left: 10px;
}
.menu-items a {
  text-decoration: none !important;
  color: #000 !important;
  margin: 5px 0;
  display: inline-block;
}
.menu-items a:hover {
  text-decoration: underline !important;
}
.menu-items a.active, .menu-top-item.accordion > .active {
  font-weight: bold;
}
a.button-link {
  color: #fff !important;
  text-decoration: none;
}

.search-auto-complete-results {
  position: absolute;
  background: #fff;
  padding: 5px;
  border: 1px solid #000;
  border-radius: 5px;
  list-style: none;
  z-index: 9999;
}
.search-auto-complete-results li {
  cursor: pointer;
  padding: 3px;
}
.search-auto-complete-results li:hover {
  background: rgb(221, 221, 221);
}
.temporary-order-line * {
  font-style: italic;
}

.image-in-list-component-box {
  position: relative;
  width: 300px !important;
  height: 340px;
  border: 1px solid #000;
  padding: 5px;
  margin-left: 10px;
}
.image-in-list-component-box img {
  display: block;
  max-width: 100%;
  max-height: 275px;
}
.image-in-list-component-box a:hover {
  opacity: 50%;
}
.image-in-list-component-box button {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
.small-button {
  font-size: 0.8rem !important;
  --bs-btn-padding-x: 0.50rem !important;
  --bs-btn-padding-y: 0.18rem !important;
}
.show-as-link {
  color: blue !important;
  text-decoration: underline;
  cursor: pointer;
}
.show-as-link:hover {
  opacity: 50%;
}

.single-view-section-wrapper {
  border: 1px solid #A9A9A9;
  border-radius: 5px;
  padding: 10px;
  display: inline-block;
  margin-bottom: 10px;
}

.blurry-text {
  filter: blur(3px); /* Adjust the value for more or less blur */
}

.cursor-pointer {
  cursor: pointer;
}
.hover-50-opacity:hover {
  opacity: 50%;
}
.invalid-feedback {
  font-weight: bold;
  text-decoration: underline;
}
.form-check * {
  cursor: pointer;
}
.form-check *:hover {
  opacity: 50%;
}
.button-link-custom {
  border: none!important;
}
.button-link-custom:hover {
  opacity: 50%;
}
.force-text-wrap-auto, .force-text-wrap-auto * {
  text-wrap: initial !important;
}
.help-text {
  font-size: 10px;
  font-weight: lighter;
  display: block;
}
.create-btn::before {
  content: "+";
  margin-right: 8px;
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
}