.date-part-input {
    width: 4ch !important; /* Width for 2 characters (for dd and mm) */
    text-align: center;
    border: none;
    border-bottom: 1px solid #ccc;
    margin-right: 3px;
    font-size: 16px;
    padding: 2px 0 !important;
}

.year-input {
    width: 6ch !important; /* Width for 4 characters (for yyyy) */
}

.dot-separator {
    display: inline-block;
    font-weight: bold;
    margin-right: 3px;
    margin-left: 3px;
    font-size: 18px;
    position: relative;
    top: 5px; /* Push the dot separator towards the bottom */
    user-select: none; /* Prevent selection of the dot */
}

.date-part-input:focus {
    outline: none;
    border-bottom: 2px solid #007bff; /* Change the bottom border on focus */
}
