.header-message-display {
    position: fixed;
    bottom: 50px;
    right: 50px;
    display: inline-block;
    z-index: 9999;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    font-size: 16px;
}


@media (max-width: 992px) {
    .header-message-display {
        top: 2em;
        left: 3rem;
        bottom: auto;
    }
}